import { playMedia } from "../../../../../assets/js/utils/playPauseMedia";

// video state classes
const STATIC_MOMENTS_PLAYING_CLASS = 'is-playing';
const STATIC_MOMENTS_FOCUSED_CLASS = 'is-focused';
const STATIC_MOMENTS_ENDED_CLASS = 'is-ended';

type DataPoint = {
  id: number;
  type: string;
  localized_name_key: string;
  clip_timestamp: number;
};

// static data for scrubber bar
// CSGO
const data1 = [
  {
    id: 1,
    type: 'SMOKE',
    localized_name_key: 'moments.timelineEvents.thrown',
    clip_timestamp: 1.06456,
  },
  {
    id: 2,
    type: 'KILL',
    localized_name_key: 'moments.timelineEvents.killedEnemy',
    clip_timestamp: 3.12958,
  },
  {
    id: 3,
    type: 'KILL',
    localized_name_key: 'moments.timelineEvents.killedEnemy',
    clip_timestamp: 12.47023,
  },
  {
    id: 4,
    type: 'KILL',
    localized_name_key: 'moments.timelineEvents.killedEnemy',
    clip_timestamp: 26.06359,
  },
  {
    id: 5,
    type: 'INCENDIARY',
    localized_name_key: 'moments.timelineEvents.thrown',
    clip_timestamp: 31.06359,
  },
  {
    id: 6,
    type: 'WIN',
    localized_name_key: 'moments.timelineEvents.roundWon',
    clip_timestamp: 40.02192,
  },
];
//DOTA2
const data2 = [
  {
    id: 1,
    type: 'ULTIMATECAST',
    localized_name_key: 'gameEvents.dota2.ultimateCast',
    clip_timestamp: 1.43291,
  },
  {
    id: 2,
    type: 'ULTIMATECAST',
    localized_name_key: 'gameEvents.dota2.ultimateCast',
    clip_timestamp: 7.14751,
  },
  {
    id: 3,
    type: 'KILL',
    localized_name_key: 'moments.timelineEvents.killedEnemy',
    clip_timestamp: 11.11741,
  },
  {
    id: 4,
    type: 'DEATH',
    localized_name_key: 'moments.timelineEvents.died',
    clip_timestamp: 15.27949,
  },
  {
    id: 5,
    type: 'SAVEDCLIP',
    localized_name_key: 'moments.timelineEvents.savedClip',
    clip_timestamp: 19.37748,
  },
];
// LOL
const data3 = [
  {
    id: 1,
    type: 'KILL',
    localized_name_key: 'moments.timelineEvents.killedEnemy',
    clip_timestamp: 9.29265,
  },
  {
    id: 2,
    type: 'KILL',
    localized_name_key: 'moments.timelineEvents.killedEnemy',
    clip_timestamp: 11.06454,
  },
  {
    id: 3,
    type: 'ASSIST',
    localized_name_key: 'gameEvents.assists',
    clip_timestamp: 26.02911,
  },
  {
    id: 4,
    type: 'SAVEDCLIP',
    localized_name_key: 'moments.timelineEvents.savedClip',
    clip_timestamp: 33.40022,
  },
];
/* FUNCTIONS */

/*
  gives users a play button
  hides on play and replaced
  by replay button after video ends
*/
function handlePlayButton(
  vid: HTMLVideoElement,
  videoPlayControl: HTMLElement,
) {
  if (vid instanceof HTMLVideoElement) {
    videoPlayControl.addEventListener('click', () => {
      playMedia(vid);
      videoPlayControl.setAttribute('tabindex', '-1');
      vid.parentElement.classList.add(STATIC_MOMENTS_PLAYING_CLASS);
      vid.parentElement.classList.remove(STATIC_MOMENTS_ENDED_CLASS);
    });
  }
}

function selectVideo(videoSelected: number) {
  // deselect all titles
  Array.from(document.querySelectorAll('.video-selector--item')).forEach(
    (el) => {
      el.classList.remove('selected');
    },
  );
  // select correct title
  let selectedTitle = document.querySelector(
    '.video-selector--item-' + videoSelected,
  );
  if (selectedTitle instanceof HTMLElement)
    selectedTitle.classList.add('selected');

  //Hide all videos
  Array.from(document.querySelectorAll('.moments-editor__container')).forEach(
    (el) => {
      let videoElem = el.querySelector('.video-player__video');
      if (videoElem instanceof HTMLVideoElement) {
        videoElem.setAttribute('tabIndex', '-1');
        videoElem.pause();
      }
      el.setAttribute('hidden', 'true');
    },
  );
  //show correct video
  const selectedVideo: HTMLElement = document.querySelector(
    '.moments-editor__container.video' + videoSelected,
  );
  var videoElem = selectedVideo.querySelector('.video-player__video');
  if (videoElem instanceof HTMLVideoElement) {
    videoElem.setAttribute('tabIndex', '0');
  }
  selectedVideo.removeAttribute('hidden');
  setupMomentVideo(selectedVideo);
}

function createTooltipForSVG(
  element: HTMLElement,
  dataPoint: DataPoint,
): HTMLElement {
  //left and top
  const vid = element.querySelector('.videoplayer');
  const timelineContainer: HTMLElement = element.querySelector(
    '.timeline-main-container',
  );
  if (!(vid instanceof HTMLVideoElement) || vid.readyState < 1) return null;
  let percentage = dataPoint.clip_timestamp / vid.duration;
  let move = percentage * (timelineContainer.offsetWidth - 44);
  const newNode = document
    .querySelector('.neededSVGs .svg-tooltip')
    .cloneNode(true) as HTMLElement;
  newNode.style.left = move + 'px';
  newNode.style.top = '-20px';
  newNode.classList.add('svg-tooltip-' + dataPoint.id);
  switch (dataPoint.type) {
    case 'DEATH':
      newNode.innerHTML = 'Died';
      break;
    case 'KILL':
      newNode.innerHTML = 'Killed Enemy';
      break;
    case 'SAVEDCLIP':
      newNode.innerHTML = 'Saved Clip';
      break;
    case 'ULTIMATECAST':
      newNode.innerHTML = 'Ultimate Cast';
      break;
    case 'ASSIST':
      newNode.innerHTML = 'Assist';
      break;
    case 'WIN':
      newNode.innerHTML = 'Win';
      break;
    case 'SMOKE':
      newNode.innerHTML = 'Smoke thrown';
      break;
    case 'INCENDIARY':
      newNode.innerHTML = 'Incendiary thrown';
      break;
    default:
      newNode.innerHTML = 'Other Event';
      break;
  }
  return newNode;
}

function setCorrectTooltipPosition(element: HTMLElement, id: number) {
  const icon = element.querySelector(`.svg-tooltip-${id}`);
  if (icon instanceof HTMLElement) {
    let currentLeft = icon.style.left;
    let leftValStr = currentLeft.substring(0, currentLeft.length - 2);
    let leftValInt = parseInt(leftValStr);
    icon.style.left = leftValInt - icon.offsetWidth / 2 + 17 + 4 + 'px';
  }
}

function createSVGIcon(element: HTMLElement, dataPoint: DataPoint) {
  let type = dataPoint.type;
  let timestamp = dataPoint.clip_timestamp;
  const vid: HTMLVideoElement = element.querySelector('.videoplayer');
  const timelineContainer: HTMLElement = element.querySelector(
    '.timeline-main-container',
  );
  if (vid.readyState < 1) return null;

  let percentage = timestamp / vid.duration;
  let move = percentage * (timelineContainer.offsetWidth - 44) + 4;
  if (
    type === 'SAVEDCLIP' ||
    type === 'ULTIMATECAST' ||
    type === 'DEFAULT' ||
    type === 'ASSIST' ||
    type === 'SMOKE' ||
    type === 'INCENDIARY'
  ) {
    const newNode = document
      .querySelector('.neededSVGs .generic')
      .cloneNode(true) as HTMLElement;
    newNode.classList.add('svg-for-tooltip-' + dataPoint.id);
    newNode.setAttribute('x', `${move}`);
    return newNode;
  }
  if (type === 'KILL') {
    const newNode = document
      .querySelector('.neededSVGs .kill')
      .cloneNode(true) as HTMLElement;
    newNode.classList.add('svg-for-tooltip-' + dataPoint.id);
    newNode.setAttribute('x', `${move}`);
    return newNode;
  }
  if (type === 'DEATH') {
    const newNode = document
      .querySelector('.neededSVGs .death')
      .cloneNode(true) as HTMLElement;
    newNode.classList.add('svg-for-tooltip-' + dataPoint.id);
    newNode.setAttribute('x', `${move}`);
    return newNode;
  }
  if (type === 'HEADSHOT') {
    const newNode = document
      .querySelector('.neededSVGs .headshot')
      .cloneNode(true) as HTMLElement;
    newNode.classList.add('svg-for-tooltip-' + dataPoint.id);
    newNode.setAttribute('x', `${move}`);
    return newNode;
  }
  if (type === 'WIN') {
    const newNode = document
      .querySelector('.neededSVGs .game-win')
      .cloneNode(true) as HTMLElement;
    newNode.classList.add('svg-for-tooltip-' + dataPoint.id);
    newNode.setAttribute('x', `${move}`);
    return newNode;
  }
  return null;
}

function refreshSVGs(element: HTMLElement) {
  if (element instanceof HTMLElement) {
    const vid: HTMLVideoElement = element.querySelector('.videoplayer');
    var data = data1;
    if (element.classList.contains('video2')) data = data2;
    if (element.classList.contains('video3')) data = data3;

    let timelineMainContainer = element.querySelector(
      '.timeline-main-container',
    );
    if (timelineMainContainer instanceof HTMLDivElement) {
      let calculatedWidth = timelineMainContainer.offsetWidth - 44;
      let calculatedWidthDots = timelineMainContainer.offsetWidth - 40;
      let calculatedWidthMargin = timelineMainContainer.offsetWidth - 22;

      let borderRect = element.querySelector('.border-rect');
      let playArea = element.querySelector('.play-area');
      let scrubber = element.querySelector('.scrubber');
      let trimmedArea = element.querySelector('.trimmed-area');
      let dottedLineDots = element.querySelector('.dotted-line-dots');
      let endMarker = element.querySelector('.end-marker');
      let marginEnd = element.querySelector('.margin-end');
      let barberStripe = element.querySelector('.barber-stripe');

      if (
        borderRect &&
        playArea &&
        scrubber &&
        trimmedArea &&
        dottedLineDots &&
        endMarker &&
        marginEnd &&
        barberStripe
      ) {
        borderRect.setAttribute('width', `${calculatedWidth}`);
        playArea.setAttribute('width', `${calculatedWidth}`);
        scrubber.setAttribute('width', `${calculatedWidth}`);
        trimmedArea.setAttribute('width', `${calculatedWidth}`);
        dottedLineDots.setAttribute('width', `${calculatedWidthDots}`);
        endMarker.setAttribute(
          'transform',
          `translate('${calculatedWidthMargin}',0) scale(-1,1)`,
        );
        marginEnd.setAttribute('x', `${calculatedWidthMargin}`);
        barberStripe.setAttribute('x', `${calculatedWidthDots}`);

        data.forEach((dataPoint) => {
          // create icon
          let icon = createSVGIcon(element, dataPoint);
          if (icon !== null) {
            icon.addEventListener('click', function (e) {
              vid.currentTime = dataPoint.clip_timestamp;
            });
            // add hover to icon
            icon.addEventListener('mouseover', (evt) => {
              element
                .querySelector('.svg-tooltip-' + dataPoint.id)
                .classList.add('show');
            });
            icon.addEventListener('mouseout', (evt) => {
              element
                .querySelector('.svg-tooltip-' + dataPoint.id)
                .classList.remove('show');
            });
            element
              .querySelector('.timeline__timeline-container')
              .appendChild(icon);
          }
          // create tooltip
          let tooltip = createTooltipForSVG(element, dataPoint);
          if (tooltip !== null) {
            element
              .querySelector('.timeline-main-container')
              .appendChild(tooltip);
            setCorrectTooltipPosition(element, dataPoint.id);
          }
        });
      }
    }
  }
}

function removeAllIcons(element: HTMLElement) {
  let allIconsToRemove = Array.from(
    element.querySelectorAll(
      '.timeline__timeline-container .timeline-icon__container',
    ),
  );
  allIconsToRemove.forEach((elem) => {
    elem.remove();
  });
  let allTooltipsToRemove = Array.from(
    element.querySelectorAll('.timeline-main-container .svg-tooltip'),
  );
  allTooltipsToRemove.forEach((elem) => {
    elem.remove();
  });
}

function whenResizeWindow(element: HTMLElement) {
  removeAllIcons(element);
  refreshSVGs(element);
}

document
  .querySelector('.video-selector--item-1')
  .addEventListener('click', () => selectVideo(1));
document
  .querySelector('.video-selector--item-2')
  .addEventListener('click', () => selectVideo(2));
document
  .querySelector('.video-selector--item-3')
  .addEventListener('click', () => selectVideo(3));

// Play in view video behavior
function momentsVideosPlayInView(videoSelected: number) {
  //show correct video
  const videoContainer = document.querySelector(
    '.moments-editor__container.video' + videoSelected,
  );
  var video: HTMLVideoElement = videoContainer.querySelector(
    '.video-player__video',
  );

  video.currentTime = 0;

  // Play video when 75% is visible in viewport
  // DOWN
  // eslint-disable-next-line no-unused-vars
  const waypointDown = new Waypoint({
    element: video,
    offset: '75%',
    handler: (direction: string) => {
      if (window.innerWidth < window.MD_WIDTH) {
        if (direction === 'down') {
          playMedia(video);
          video.parentElement.classList.add(STATIC_MOMENTS_PLAYING_CLASS);
          video.parentElement.classList.remove(STATIC_MOMENTS_ENDED_CLASS);
        }
      }
    },
  });
  // UP
  // eslint-disable-next-line no-unused-vars
  const waypointUp = new Waypoint({
    element: video,
    offset: '-75%',
    handler: (direction: string) => {
      if (window.innerWidth < window.MD_WIDTH) {
        if (direction === 'up') {
          playMedia(video);
          video.parentElement.classList.add(STATIC_MOMENTS_PLAYING_CLASS);
          video.parentElement.classList.remove(STATIC_MOMENTS_ENDED_CLASS);
        }
      }
    },
  });
}

function setupMomentVideo(element: HTMLElement) {
  if (element.hasAttribute('hidden')) return;

  const vid: HTMLVideoElement = element.querySelector('.videoplayer');
  const seekCursor = element.querySelector('.cursor__cursor-path');
  const videoPlayControl = element.querySelector('.video-play-control');
  const playArea: SVGElement = element.querySelector('.play-area');
  const timelineContainer: HTMLElement = element.querySelector(
    '.timeline-main-container',
  );

  playArea.onclick = function (e) {
    const eventTarget = e.target as Element;
    let offset = eventTarget.getBoundingClientRect().x;
    let left = e.pageX - offset;
    let totalWidth = timelineContainer.offsetWidth - 44;
    let percentage = left / totalWidth;
    let vidTime = vid.duration * percentage;
    vid.currentTime = vidTime;
  };

  if (videoPlayControl instanceof HTMLElement) {
    handlePlayButton(vid, videoPlayControl);
  }

  vid.ontimeupdate = function () {
    let percentage = vid.currentTime / vid.duration;
    let move = percentage * (timelineContainer.offsetWidth - 44);
    seekCursor.setAttribute(
      'd',
      'M' + (22 + move) + ' 0 l -2 -2 l 4 0 l -2 2 v 36',
    );
  };

  window.onresize = () => whenResizeWindow(element);
  vid.onloadeddata = () => {
    refreshSVGs(element);
  };
  removeAllIcons(element);
  refreshSVGs(element);
}

window.addEventListener('DOMContentLoaded', (event) => {
  let momentsVideo: HTMLElement = document.querySelector(
    '.moments-editor__container.video1',
  );
  setupMomentVideo(momentsVideo);
  let momentsVideos = Array.from(
    document.querySelectorAll('.video-player__video'),
  );
  momentsVideos.forEach((element: HTMLVideoElement) => {
    setupAccessibility(element);
  });
  momentsVideosPlayInView(1);
});

// ACCESSIBILITY
function setupAccessibility(video: HTMLVideoElement) {
  video.addEventListener('focus', () => {
    video.setAttribute('controls', 'controls');
    if (video === document.activeElement) {
      video.parentElement.classList.add(STATIC_MOMENTS_FOCUSED_CLASS);
    }
    // show tooltip on already playing videos
    if (!video.paused) {
      video.parentElement.classList.add(STATIC_MOMENTS_PLAYING_CLASS);
    }
    video.parentElement.classList.remove(STATIC_MOMENTS_ENDED_CLASS);
  });
  // add tooltip if focused video plays
  video.addEventListener('play', () => {
    if (video === document.activeElement) {
      video.parentElement.classList.add(STATIC_MOMENTS_PLAYING_CLASS);
    }
  });
  // remove tooltip when video ends or pauses
  video.addEventListener('pause', () => {
    video.parentElement.classList.remove(STATIC_MOMENTS_PLAYING_CLASS);
  });
  video.addEventListener('ended', () => {
    video.parentElement.classList.remove(STATIC_MOMENTS_PLAYING_CLASS);
  });
  video.addEventListener('blur', () => {
    video.removeAttribute('controls');
    video.parentElement.classList.remove(STATIC_MOMENTS_FOCUSED_CLASS);
  });
}
